@import "variables";
@import "@coreui/coreui/scss/coreui";

@import "custom";
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs4-alert';


.card-group {
    flex-flow: row wrap;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card{
    border: none !important;
}

.bg-blue {
    background-color: #1c2e71 !important;
    color: #ffffff !important;
}

.modal-blue .modal-header {
    color: #fff;
    background-color: #1c2e71;
}

.btn-blue {
    color: #fff;
    background-color: #1c2e71;
    border-color: #1c2e71;
}

.sidebar {
    background: #101e55;
}

.sidebar .nav-link.active {
    color: #fff;
    background: #182765;
}

.sidebar .nav-link:hover {
    color: #fff;
    background: #1c2e71;
}

.btn-blue:hover {
    color: #f0f04a;
    text-decoration: none;
}

.punch_time{
    color: #4c4c4c;
    font-weight: 500;
    font-style: italic;
}

.total_hr_red{
    color: #ef1212;
    font-weight: 500;
    // font-style: italic;
}

.total_hr_green{
    color: #038108;
    font-weight: 500;
    // font-style: italic;
}

.breadcrumb {
    border-bottom: 0px solid #c8ced3;
}

.table-bordered th, .table-bordered td {
    border: 1px solid #e1e2e3 !important;
}